
require("./../node_modules/@module-federation/nextjs-mf/src/include-defaults.js");
const PageNotFound = () => {
  return (
    <div>
      <h1>Error 404</h1>
      <h2>We are sorry. The page you are looking for cannot be found</h2>
    </div>
  );
};

export default PageNotFound;
