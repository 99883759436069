import Head from 'next/head';
import React, { FC, useMemo } from 'react';

import { favicon, VIEW_RESOLVER } from '@/data/constants';
import { useStructuredDataForSeo } from '@/hooks';
import { isBrowser } from './page-seo-meta-helpers';
import { TPageMetaExtra } from './page-seo-meta.interface';

export interface IPageMetaSeoProps {
  /** The app title that should be piped with the meta data from view definition */
  apiVersion?: string;
  /** The View Definition */
  seoMetaData?: object;
  /** Current page URL base from the router */
  currentUrlBase?: string;
  /** The child components */
  children?: React.ReactNode;
}
const { API_VERSION_V2 } = VIEW_RESOLVER;
/**
 * Component to inject SEO data from the view definition
 */
export const PageMetaSeo: FC<IPageMetaSeoProps> = ({
  currentUrlBase,
  seoMetaData,
  apiVersion = API_VERSION_V2,
  children,
}) => {
  const { title, headline, description, addMetaNoRobots, canonicalUrlSlug, jsonLd } = (seoMetaData ||
    {}) as TPageMetaExtra;

  // Construct the title
  const pageTitle = useMemo(() => {
    const titleValue = apiVersion === API_VERSION_V2 ? [title, headline] : [title, headline];
    return titleValue.filter(Boolean).join(' | ');
  }, [title, headline, apiVersion]);

  // Construct the canonical URL
  const canonicalUrl = useMemo(() => {
    if (canonicalUrlSlug?.startsWith('https://')) {
      return canonicalUrlSlug;
    }
    if (currentUrlBase && canonicalUrlSlug) {
      const joinStringValue = canonicalUrlSlug.startsWith('/') ? '' : '/';
      return [currentUrlBase, canonicalUrlSlug].filter(Boolean).join(joinStringValue);
    }
    if (isBrowser()) {
      return window.location.href;
    }
    return currentUrlBase;
  }, [currentUrlBase, canonicalUrlSlug]);

  // Construct the robots meta tag content
  const robotsContent = addMetaNoRobots ? 'noindex, nofollow' : 'index, follow';

  // Construct the structured data
  const structuredData = useStructuredDataForSeo(jsonLd, currentUrlBase || '');

  if (!seoMetaData) {
    return null;
  }

  return (
    <>
      <Head>
        {pageTitle ? <title key="page-title">{pageTitle}</title> : null}
        {description ? <meta key="page-desc" name="description" content={description} /> : null}
        {canonicalUrl ? <link key="canonical" rel="canonical" href={canonicalUrl} /> : null}
        <meta key="robots" name="robots" content={robotsContent} />
        <link rel="shortcut icon" href={favicon} />
        {structuredData ? (
          <script key="jsonLd" type="application/ld+json">
            {structuredData}
          </script>
        ) : null}
      </Head>
      {children}
    </>
  );
};
