import { FC } from 'react';

import { PageMetaSeo } from '@/components/page-seo-meta';
import { ISeoWrapperProps } from './seo-wrapper.interface';

const SeoWrapper: FC<ISeoWrapperProps> = ({ seoMetaData, currentUrlBase, children }) => (
  <>
    <PageMetaSeo currentUrlBase={currentUrlBase} seoMetaData={seoMetaData} />
    {children}
  </>
);

export default SeoWrapper;
