import * as Sentry from '@sentry/react';
import dynamic from 'next/dynamic';

import SeoWrapper from '@/components/seo-wrapper/seo-wrapper';
import { Spinner } from '@/components/shared';

import PageNotFound from '@/pages/404';
import { IPageDefinition } from '@/components/authored-page-routes/authored-page-routes.interface';

const Page = dynamic(() => import('@/components/common/common.component').then((module) => module.default), {
  ssr: false,
  loading: () => <Spinner />,
});

const AuthoredPageRoutes: React.FC<IPageDefinition> = ({ viewDefinitionResults, themeTokens }) => {
  if (viewDefinitionResults?.isError) {
    console.error(viewDefinitionResults.error);
    Sentry.captureMessage('BFF api failed - b3-ID: ' + viewDefinitionResults?.error?.b3);
    return <PageNotFound />;
  }
  /** this console is needed to print the b3ID for every request **/
  console.log('bff data ====>', viewDefinitionResults?.bffData);
  const viewMetaData = viewDefinitionResults?.viewDefinition?.viewMetaData;
  const pageId = viewDefinitionResults?.pageID;

  return (
    <SeoWrapper seoMetaData={viewMetaData} currentUrlBase={viewDefinitionResults?.currentUrlBase}>
      <Page
        viewDefinition={viewDefinitionResults?.viewDefinition}
        themeTokens={themeTokens ?? undefined}
        pageId={pageId}
        headerSection={viewDefinitionResults?.headerSection}
      />
    </SeoWrapper>
  );
};

export default AuthoredPageRoutes;
